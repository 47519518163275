/* globals isDevMode */
import * as Sentry from '@sentry/browser';

if (!isDevMode) {
    Sentry.onLoad(function() {
        Sentry.init({
            dsn:"https://e2ed6ddcdded4232b6c1fab25596a6b1@o4504836026597376.ingest.us.sentry.io/4504888717410304",
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    });
}

